import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import { forceWait } from 'apputil/retry-promise';
import {
  createSubscription,
  updateSubscritionState,
  retrySubscription,
} from 'data/oncion';
import buildDataLayer from 'data-layer';
import {
  gaLoggedInLabel,
  getHeldSubscription,
  getSubscriptionInvoiceId,
} from 'state/selectors';

import { INFO_ICON } from 'constants/font-awesome';
import { ACCOUNT_ROUTE } from 'constants/navigation';

export default ({ stateContext, noticationContext }) => {
  const logger = buildLogger();
  const dataLayer = buildDataLayer();

  const { dispatch, getToken } = stateContext;
  const { showNotification, types } = noticationContext;

  const onError = ({
    trace,
    error = {},
    notification = 'There has been problem, sorry please try again',
    caller = 'subscription-service',
  } = {}) => {
    showNotification({ message: notification, type: error ? types.error : types.info });
    if (error) {
      const { stack = '', message = '' } = error;
      logger.error(new LoggableError({
        data: { stack }, message, caller, trace,
      }));
    }
  };

  return {
    createSubscription: async ({ paymentMethodId, priceId, subscriptionType }) => {
      try {
        const getLoggedInService = await import('services/logged-in-service');
        const { loggedIn } = getLoggedInService.default({
          stateContext,
        });
        const isLoggedIn = await loggedIn();
        if (!isLoggedIn) {
          logger.info({ caller: 'subscription-service:createSubscription', message: 'logged-out' });
          dataLayer.trackPass({
            descriptor: 'create_subscription_unauthenticated',
            label: `${gaLoggedInLabel()}`,
          });
          return {};
        }
        const { token } = await getToken();
        await forceWait({ time: 500 });
        const { subscription, paymentTrial, invoiceId } = await createSubscription({
          paymentMethodId, priceId, token, subscriptionType,
        });
        // save the sub in state probably not all this
        dispatch({
          type: 'set-subscription',
          payload: {
            subscription: {
              ...subscription,
              invoiceId,
            },
          },
        });
        if (paymentTrial !== null) {
          return { paymentTrial, invoiceId, status: subscription.stripeSubscriptionState };
        }
        if (subscription.stripeSubscriptionState === 'active') {
          dispatch({
            type: 'set-messages',
            payload: {
              messages: [{
                icon: INFO_ICON,
                type: 'info',
                title: 'Manage your subscription',
                message: 'For more information go to you account.',
                route: ACCOUNT_ROUTE,
                aria: 'go to account',
              }],
            },
          });
        }
        return {
          valid: subscription.stripeSubscriptionState === 'active',
          status: subscription.stripeSubscriptionState,
        };
      } catch (error) {
        const trace = await StackTrace.get();
        onError({
          trace,
          error,
          caller: 'subscription-service:createSubscription',
          notification: 'There has been issue creating your Subscription. Sorry please try again later.',
        });
        dataLayer.trackStripe({
          descriptor: 'create_subscription_error',
          label: `${gaLoggedInLabel()}`,
        });
        return { invalid: true };
      }
    },
    retrySubscription: async ({ priceId, paymentMethodId, subscriptionType }) => {
      try {
        const getLoggedInService = await import('services/logged-in-service');
        const { loggedIn } = getLoggedInService.default({
          stateContext,
        });
        const isLoggedIn = await loggedIn();
        if (!isLoggedIn) {
          logger.info({ caller: 'subscription-service:retrySubscription', message: 'logged-out' });
          dataLayer.trackPass({
            descriptor: 'create_subscription_unauthenticated',
            label: `${gaLoggedInLabel()}`,
          });
          return {};
        }
        const invoiceId = await getSubscriptionInvoiceId();
        if (!invoiceId) {
          logger.info({ caller: 'subscription-service:retrySubscription', message: 'invalid-state' });
          dataLayer.trackPass({
            descriptor: 'create_subscription_invalid_state',
            label: `${gaLoggedInLabel()}`,
          });
          return {};
        }

        const { token } = await getToken();
        await forceWait({ time: 500 });
        const retryData = await retrySubscription({
          invoiceId, paymentMethodId, token,
        });
        // save the sub in state probably not all this
        dispatch({
          type: 'set-subscription',
          payload: {
            subscription: {
              ...retryData.subscription,
              invoiceId: retryData.invoiceId,
            },
          },
        });
        if (retryData.paymentTrial !== null) {
          return {
            paymentTrial: retryData.paymentTrial,
            invoiceId: retryData.invoiceId,
            status: retryData.subscription.stripeSubscriptionState,
          };
        }
        if (retryData.subscription.stripeSubscriptionState === 'active') {
          dispatch({
            type: 'set-messages',
            payload: {
              messages: [{
                icon: INFO_ICON,
                type: 'info',
                title: 'Manage your subscription',
                message: 'For more information go to you account.',
                route: ACCOUNT_ROUTE,
                aria: 'go to account',
              }],
            },
          });
        }
        return {
          valid: retryData.subscription.stripeSubscriptionState === 'active',
          status: retryData.subscription.stripeSubscriptionState,
        };
      } catch (error) {
        const trace = await StackTrace.get();
        onError({
          trace,
          error,
          caller: 'subscription-service:createSubscription',
          notification: 'There has been issue creating your Subscription. Sorry please try again later.',
        });
        dataLayer.trackStripe({
          descriptor: 'create_subscription_error',
          label: `${gaLoggedInLabel()}`,
        });
        return { invalid: true };
      }
    },
    updateSubscritionState: async ({ stripeSubscriptionState }) => {
      try {
        const getLoggedInService = await import('services/logged-in-service');
        const { loggedIn } = getLoggedInService.default({
          stateContext,
        });
        const isLoggedIn = await loggedIn();
        if (!isLoggedIn) {
          logger.info({ caller: 'subscription-service:updateSubscritionState', message: 'logged-out' });
          dataLayer.trackPass({
            descriptor: 'update_subscription_unauthenticated',
            label: `${gaLoggedInLabel()}`,
          });
          return {};
        }
        const { token } = await getToken();
        await forceWait({ time: 500 });
        const { subscription } = await updateSubscritionState({
          stripeSubscriptionState, token,
        });
        const currentSubscription = await getHeldSubscription();
        dispatch({
          type: 'set-subscription',
          payload: { subscription: { ...currentSubscription, subscription } },
        });
        if (stripeSubscriptionState === 'active') {
          dispatch({
            type: 'set-messages',
            payload: {
              messages: [{
                icon: INFO_ICON,
                type: 'info',
                title: 'Manage your subscription',
                message: 'For more information go to you account.',
                route: ACCOUNT_ROUTE,
                aria: 'go to account',
              }],
            },
          });
        }
        return subscription;
      } catch (error) {
        const trace = await StackTrace.get();
        onError({
          trace,
          error,
          caller: 'subscription-service:updateSubscritionState',
          notification: 'There has been issue creating your Subscription. Sorry please try again later.',
        });
        dataLayer.trackStripe({
          descriptor: 'update_subscription_error',
          label: `${gaLoggedInLabel()}`,
        });
        return {};
      }
    },
  };
};
