import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { string, func, bool } from 'prop-types';

import { buildLogger, LoggableError } from 'logger';
import buildDataLayer from 'data-layer';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { ALL_CARDS_ROUTE, SUBSCRIPTION_ROUTE } from 'constants/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons';

import { useStateContext } from 'contexts/state-context';
import { useNoticationContext } from 'contexts/notification-context';
import { gaLoggedInLabel } from 'state/selectors';

import getManageSubscriptionService from 'services/subscription/mange-subscription-service';

import {
  Grid, Hidden, Button, Typography, CircularProgress,
} from '@material-ui/core';
import StripeCardSection from './stripe-card-section';
import useStyles from './styles';

const StripeCheckout = ({
  priceId,
  canSubmit,
  subscriptionType = '',
  stripeScriptLoading,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();
  const logger = buildLogger();
  const dataLayer = buildDataLayer();

  const [loading, setLoading] = useState(false);

  const [retry, setRetry] = useState(false);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();
  const { showNotification, types } = noticationContext;

  const {
    createSubscription,
    updateSubscritionState,
    retrySubscription,
  } = getManageSubscriptionService({
    stateContext,
    noticationContext,
  });

  const onSubscriptionSuccess = () => {
    dataLayer.trackStripe({
      descriptor: 'create_subscription_success',
      label: subscriptionType.replace(/ .*/, ''),
      fbData: {
        subscription: subscriptionType.replace(/ .*/, ''),
      },
    });
    showNotification({
      message: 'Your subscription has successfully been set up.',
      type: types.success,
    });
    setTimeout(() => {
      setLoading(false);
      navigate(ALL_CARDS_ROUTE);
    }, 1000);
    return true;
  };

  const onSubscriptionError = async ({
    error = {}, caller,
    descriptor, message = 'Something has gone wrong, sorry please try again.',
  }) => {
    const messageToUse = error.message ?? message;

    logger.error(new LoggableError({
      data: { error, caller },
      message: messageToUse,
    }));
    dataLayer.trackStripe({
      descriptor,
      label: `${gaLoggedInLabel()}: ${messageToUse}`,
    });
    showNotification({
      message: messageToUse,
      type: types.error,
    });
    setLoading(false);
    return false;
  };

  const onRetry = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (canSubmit()) {
      setLoading(true);
      try {
        dataLayer.trackStripe({
          descriptor: 'retry_buy_subscription',
          label: `${gaLoggedInLabel()}: ${subscriptionType}`,
        });
        const cardElement = elements.getElement(CardElement);
        const createPaymentResult = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (createPaymentResult.error) {
          return onSubscriptionError({
            error: createPaymentResult.error,
            caller: 'stripe.createPaymentMethod',
            descriptor: 'create_payment_method_error',
          });
        }

        const { id } = createPaymentResult.paymentMethod;
        const retrySubscriptionResult = await retrySubscription({
          priceId,
          paymentMethodId: id,
          subscriptionType,
        });

        if (retrySubscriptionResult.invalid) {
          setTimeout(() => navigate(SUBSCRIPTION_ROUTE), 2000);
        }

        if (retrySubscriptionResult.valid) {
          await updateSubscritionState({ stripeSubscriptionState: 'active' });
          return onSubscriptionSuccess();
        }

        if (retrySubscriptionResult.paymentTrial) {
          const retryConfirmCardResult = await stripe.confirmCardPayment(
            retrySubscriptionResult.paymentTrial.client_secret, {
              payment_method: id,
            },
          );

          if (retryConfirmCardResult.error) {
            return onSubscriptionError({
              error: retryConfirmCardResult.error,
              caller: 'stripe.confirmCardPayment',
              descriptor: 'confirm_card_payment_error',
              message: 'There was a problem processing youer card, sorry please try again later.',
            });
          }

          if (retryConfirmCardResult.paymentIntent.status === 'succeeded') {
            await updateSubscritionState({ stripeSubscriptionState: 'active' });
            dataLayer.trackStripe({
              descriptor: 'success_retry_buy_subscription',
              label: `${gaLoggedInLabel()}: ${subscriptionType}`,
            });
            return onSubscriptionSuccess();
          }
        }
        // game over
        setLoading(false);
        return false;
      } catch (err) {
        return onSubscriptionError({
          error: err,
          caller: 'StripeCheckout.onRetry',
          descriptor: 'create_payment_method_unknown_error',
        });
      }
    }
    setLoading(false);
    return false;
  };

  const onClick = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (canSubmit()) {
      setLoading(true);
      try {
        dataLayer.trackStripe({
          descriptor: 'try_buy_subscription',
          label: `${gaLoggedInLabel()}: ${subscriptionType}`,
        });
        const cardElement = elements.getElement(CardElement);
        const createPaymentResult = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (createPaymentResult.error) {
          return onSubscriptionError({
            error: createPaymentResult.error,
            caller: 'stripe.createPaymentMethod',
            descriptor: 'create_payment_method_error',
          });
        }

        const { id } = createPaymentResult.paymentMethod;
        const createSubscriptionResult = await createSubscription({
          paymentMethodId: id,
          priceId,
          subscriptionType,
        });

        if (createSubscriptionResult.valid) {
          return onSubscriptionSuccess();
        }

        if (createSubscriptionResult.paymentTrial) {
          const createConfirmCardResult = await stripe.confirmCardPayment(
            createSubscriptionResult.paymentTrial.client_secret, {
              payment_method: id,
            },
          );

          if (createConfirmCardResult.error) {
            setRetry(true);
            return onSubscriptionError({
              error: createConfirmCardResult.error,
              caller: 'stripe.confirmCardPayment',
              descriptor: 'confirm_card_payment_error',
              message: 'There was a problem with your card, please try again or a different payment method.',
            });
          }

          if (createConfirmCardResult.paymentIntent.status === 'succeeded') {
            await updateSubscritionState({ stripeSubscriptionState: 'active' });
            dataLayer.trackStripe({
              descriptor: 'success_buy_subscription',
              label: `${gaLoggedInLabel()}: ${subscriptionType}`,
            });
            return onSubscriptionSuccess();
          }
        }
      } catch (err) {
        return onSubscriptionError({
          error: err,
          caller: 'StripeCheckout.onClick',
          descriptor: 'create_payment_method_unknown_error',
        });
      }
    }
    setLoading(false);
    return false;
  };

  return (
    <>
    <Grid item xs={12} sm={10} className={styles.noTopMargin}>
      <StripeCardSection loading={stripeScriptLoading}/>
    </Grid>
    {retry && (
      <Grid item xs={12} sm={10}>
        <Typography
            variant="h5"
            component="p">
          {'There was an issue with your card. Please try again or use a different card.'}
        </Typography>
      </Grid>
    )}
      <Grid item xs={12} sm={10} className={styles.noTopMargin}>
        <Hidden smUp>
          <Button
            variant="contained"
            color="primary"
            className={styles.formbutton}
            onClick={onClick}
            type="submit"
            fullWidth
            aria-label="buy subscription"
            disabled={!stripe || loading}
            endIcon={loading
              ? <CircularProgress className={styles.submitLoading}/>
              : (
                <FontAwesomeIcon icon={faShoppingCart} className={styles.submitIcon}/>
              )
            }
            >
           {`${retry ? 'Retry' : 'Enable'}`}
         </Button>
        </Hidden>
        <Hidden xsDown>
          <Button
            variant="contained"
            color="primary"
            className={styles.formbutton}
            onClick={retry ? onRetry : onClick}
            type="submit"
            fullWidth
            aria-label="buy subscription"
            disabled={!stripe || loading}
            endIcon={loading
              ? <CircularProgress className={styles.submitLoading}/>
              : (
                <FontAwesomeIcon icon={faShoppingCart} className={styles.submitIcon}/>
              )
            }
            >
           {`${retry ? 'Retry' : 'Enable'} Subscription`}
         </Button>
        </Hidden>
    </Grid>
    </>
  );
};

StripeCheckout.propTypes = {
  priceId: string.isRequired,
  subscriptionType: string.isRequired,
  canSubmit: func.isRequired,
  stripeScriptLoading: bool.isRequired,
};

export default StripeCheckout;
