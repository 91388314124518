import React from 'react';
import { bool } from 'prop-types';
import { CardElement } from '@stripe/react-stripe-js';
import { Skeleton } from '@material-ui/lab';

import theme from 'theme';

import { Typography, Box } from '@material-ui/core';
import useStyles from './styles';

const { palette } = theme;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      width: '100%',
      backgroundColor: palette.background.default,
      iconColor: palette.secondary.bright,
      color: palette.text.primary,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '24px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: palette.text.primary,
      },
      '::placeholder': {
        color: palette.text.disabled,
      },
    },
    invalid: {
      iconColor: palette.error.main,
      color: palette.error.main,
    },
  },

};

const StripeCardSection = ({ loading = true }) => {
  const styles = useStyles();

  return (
      <>
      <Typography
          className={styles.marginTopBottom}
          variant="h5"
          component="label"
          color="inherit"
          htmlFor="card-input">
        Card details
      </Typography>
      {loading ? (
          <Skeleton animation="wave" height={100} className={styles.SkeletonContainer} />
      ) : (
        <Box
          className={styles.cardContainer}
          borderRadius={4}
          >
            <CardElement id="card-input" options={CARD_ELEMENT_OPTIONS} />
        </Box>
      )}
    </>
  );
};

StripeCardSection.propTypes = { loading: bool };

export default StripeCardSection;
