import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  bigSpaceBelow: {
    marginBottom: theme.spacing(3),
  },
  noTopMargin: {
    paddingTop: '0 !important',
    marginTop: '0 !important',
  },
  marginTopBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
    marginBottom: '0 !important',
  },
  cardContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  SkeletonContainer: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-2),
  },
});
